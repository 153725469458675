import { PricingTable, PricingTableContainer } from "components/pricing-table"

import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import SEO from "components/seo"
import useTranslations from "components/use-translations"

export default function Magaza() {
  const {
    genel: { iletisimeGec, ucretsizDene },
    magaza: { sinirsiz, yil },

    servishaneMagaza: {
      seoTitle,
      seoDescription,
      servishanePaketleri,
      ucretsizUyelik,
      standartUyelik,
      vipUyelik,
      sizeOzelProgram,
      ucretsiz,
      kaynakKodTeklifIsteyiniz,
      moduller,
      ozelModuller,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/servishane/paketler"
        datePublished="2020-02-21T15:54:17+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          { path: "https://www.kodvizit.com/servishane", name: "Servishane" },
        ]}
      />
      <Container className="mb-5">
        <h1 className=" mt-4 font-weight-light ">{servishanePaketleri}</h1>
      </Container>
      <div
        className="text-center"
        style={{ paddingBottom: 80, minHeight: 500 }}
      >
        <PricingTableContainer>
          <PricingTable
            header={ucretsizUyelik}
            colorName="secondary"
            featured={false}
            price={ucretsiz}
            buttonText={ucretsizDene}
            href="http://www.servishane.com/crm"
            amount={`1 ${yil}`}
            includingModules={[
              ...moduller.slice(0, 3),
              ...moduller.slice(6, 16),
            ]}
            excludingModules={moduller.slice(16, moduller.length)}
          />
          <PricingTable
            header={standartUyelik}
            colorName="info"
            featured={true}
            price={`990,00 ₺/${yil}`}
            buttonText={ucretsizDene}
            href="http://www.servishane.com/crm"
            amount={`1 ${yil}`}
            includingModules={moduller.slice(3, 22)}
            excludingModules={moduller.slice(22, moduller.length)}
          />
          <PricingTable
            header={vipUyelik}
            colorName="success"
            featured={false}
            price={`1590,00 ₺/${yil}`}
            buttonText={ucretsizDene}
            href="http://www.servishane.com/crm"
            amount={`1 ${yil}`}
            includingModules={moduller.slice(3, moduller.length)}
            excludingModules={[]}
          />

          <PricingTable
            header={sizeOzelProgram}
            colorName="danger"
            featured={false}
            price={kaynakKodTeklifIsteyiniz}
            href="http://www.servishane.com/"
            buttonText={iletisimeGec}
            amount={sinirsiz}
            includingModules={ozelModuller}
            excludingModules={[]}
          />
        </PricingTableContainer>
      </div>
    </Layout>
  )
}
